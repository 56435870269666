import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import BookIcon from '@material-ui/icons/Book'
import CloseIcon from '@material-ui/icons/Close'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { forwardRef, useState } from 'react'

export type ProductsProps = {
  product: {
    id: string | undefined
    title: string | undefined
    imageUrl: string | undefined
    description: string | undefined
    productShopLinks:
      | ReadonlyArray<
          { name: string | undefined; url: string | undefined } | undefined
        >
      | undefined
    ebook?: boolean | undefined
  }
  imageHeight: number | string
}

const useStyles = makeStyles({
  media: {
    minHeight: 120,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    ml: 2,
    flex: 1,
  },
})

const Product = forwardRef<HTMLDivElement, ProductsProps>((props, ref) => {
  const {
    amazonImage,
    thaliaImage,
    hugendubelImage,
  } = useStaticQuery<GatsbyTypes.ProductQuery>(graphql`
    query Product {
      amazonImage: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "amazon.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [JPG, WEBP]
          )
        }
      }
      thaliaImage: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "thalia.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [JPG, WEBP]
          )
        }
      }
      hugendubelImage: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "hugendubel.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [JPG, WEBP]
          )
        }
      }
    }
  `)

  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const {
    product: { title, imageUrl, description, productShopLinks, ebook },
    imageHeight,
  } = props
  if (!title) {
    return null
  }
  const regex = /(<([^>]+)>)/gi
  let text = description
  if (description) {
    text = description.replace(regex, '')
    if (text.length > 250) {
      text = `${text.substr(0, 250)}...`
    }
  }

  const shopLinks = []

  const amazonProductShopLink = productShopLinks?.find(
    (productShopLink) => productShopLink?.name === 'Amazon',
  )

  if (amazonProductShopLink?.url && amazonImage) {
    shopLinks.push({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      alt: amazonProductShopLink.name!,
      url: `${amazonProductShopLink.url}?tag=baedeker-21`,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      imageData: amazonImage && getImage(amazonImage)!,
    })
  }

  const thaliaProductShopLink = productShopLinks?.find(
    (productShopLink) => productShopLink?.name === 'Thalia',
  )

  if (thaliaProductShopLink?.url && thaliaImage) {
    shopLinks.push({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      alt: thaliaProductShopLink.name!,
      url: `https://www.awin1.com/cread.php?awinmid=14158&awinaffid=577183&pref1=baedeker&p=${encodeURIComponent(
        thaliaProductShopLink.url,
      )}`,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      imageData: thaliaImage && getImage(thaliaImage)!,
    })
  }

  const hugendubelProductShopLink = productShopLinks?.find(
    (productShopLink) => productShopLink?.name === 'Hugendubel',
  )

  if (hugendubelProductShopLink?.url && hugendubelImage) {
    shopLinks.push({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      alt: hugendubelProductShopLink.name!,
      url: hugendubelProductShopLink.url,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      imageData: hugendubelImage && getImage(hugendubelImage)!,
    })
  }

  return (
    <>
      <Card ref={ref} variant="outlined" square className={classes.card}>
        <Box position="relative">
          <CardMedia
            image={imageUrl}
            title={title}
            className={classes.media}
            style={{ height: imageHeight }}
          />
          {ebook && (
            <Box
              position="absolute"
              bottom={0}
              left={0}
              display="flex"
              justifyContent="space-between"
              width="100%"
              padding={1}
              style={{ backgroundColor: 'rgba(0,0,0,0.5)', color: '#fff' }}
            >
              <Typography color="inherit">
                Auch als E-Book erhältlich
              </Typography>
              <BookIcon color="inherit" />
            </Box>
          )}
        </Box>

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {text}
          </Typography>
        </CardContent>

        {!!shopLinks.length && (
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(true)
              }}
            >
              Jetzt Kaufen
            </Button>
          </CardActions>
        )}
      </Card>

      <Dialog
        maxWidth="lg"
        fullScreen={!mdUp}
        onClose={() => {
          setOpen(false)
        }}
        open={open}
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpen(false)
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Typography
              className={classes.dialogTitle}
              variant="h6"
              component="div"
            >
              {title}
            </Typography>
          </Toolbar>
        </AppBar>

        <Box padding={2}>
          <Typography paragraph>
            In der Buchhandlung kaufen <strong>oder hier bestellen</strong>
          </Typography>

          {!!productShopLinks?.length && (
            <div style={{ padding: 8, minWidth: mdUp ? 768 : 0 }}>
              <Grid container spacing={2}>
                {shopLinks.map(({ alt, url, imageData }) => (
                  <Grid key={url} item xs={12} sm={6} md={4}>
                    <a
                      href={url}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <GatsbyImage
                        image={imageData}
                        alt={alt}
                        style={{
                          border: '1px solid #d3d3d3',
                          borderRadius: '5px',
                        }}
                      />
                    </a>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </Box>
      </Dialog>
    </>
  )
})

Product.displayName = 'Products'

export default Product
